<template>
    <div class="news-list" v-if="translates && translates[langUrl]">
        <div class="news-list__title">{{ translates[langUrl].listPage_title[lang] }}</div>
        <div class="news-list__container">
            <template v-for="(item, i) in news">
                <div 
                    :key="i"
                    class="news-list__item"
                    @click="open( item.code )">
                    <div class="news-list__item__picture" :style="`background-image: url(${ imageSrc(item.picture) })` "></div>
                    <div class="news-list__item__text">
                        <!-- <div class="news-list__item__date">{{ postTime(item.createdAt) }}</div> -->
                        <div class="news-list__item__name">{{ item.title }}</div>
                        <div class="news-list__item__description">{{ item.preview }}</div>
                        <div class="news-list__item__more">{{ translates[langUrl].news_detail[lang] }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import moment from '@/plugins/moment';


    export default {
        name: 'NewsList',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {},
        data: () => ({
            imageSrc,
            langUrl: 'News'
        }),
        computed: {
            ...mapState('news', {
                news: state => state.entities
            }),
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getNews();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getNews() {
                await store.dispatch('news/fetch', {});
            },
            postTime(date) {
                return moment(date).calendar()
            },
            async open(code) {
                // await store.commit('news/CLEAR_ENTITY');
                await this.$router.push({ name: 'news', params: { code } });
            },
            checkPosition() {
                // Нам потребуется знать высоту документа и высоту экрана:
                const height = document.body.offsetHeight
                const screenHeight = window.innerHeight

                // Они могут отличаться: если на странице много контента,
                // высота документа будет больше высоты экрана (отсюда и скролл).

                // Записываем, сколько пикселей пользователь уже проскроллил:
                const scrolled = window.scrollY

                // Обозначим порог, по приближении к которому
                // будем вызывать какое-то действие.
                // В нашем случае — четверть экрана до конца страницы:
                const threshold = height - screenHeight / 4

                // Отслеживаем, где находится низ экрана относительно страницы:
                const position = scrolled + screenHeight

                if (position >= threshold) {
                    // Если мы пересекли полосу-порог, вызываем нужное действие.
                }
            }
        }
    }

</script>

<style lang="scss">
    .news-list {
        padding: 40px 24px 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        @media all and (min-width: 1400px) {
            max-width: 1400px;
            padding: 70px 0 0;
        }
        @media all and (max-width: 768px) {
            padding: 30px 20px 0;
        }
        &__title{
            display: flex;
            justify-content: flex-start;
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
        }
        &__container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 30px;
            flex-wrap: wrap;
            margin-top: 30px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                font-size: 16px;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 12px;
            overflow: hidden;
            width: 445px;
            // min-width: 350px;
            &:last-of-type {
                margin-right: 0;
            }
            @media all and (max-width: 768px) {
                width: 335px;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &__picture {
                width: 100%;
                height: 180px;
                background-position: center;
                background-size: cover;
            }
            &__text {
                padding: 24px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
            }
            &__date {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #93A3B4;
                min-height: 21px;
            }
            &__name {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: -0.02em;
                color: #273155;
                text-align: left;
                margin-top: 12px;
                min-height: 48px;
            }
            &__description {
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #273155;
                text-align: left;
                margin-top: 8px;
                height: 95px;
                overflow: hidden;
            }
            &__more {
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #D80765;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }
</style>